import _ from 'lodash';
import { captureException, withScope } from '@sentry/browser';
import { ActionCreator } from 'redux';

import {
  BulletNoteStatus,
  PatientLoadDuration,
  PatientLoadDurations,
  PatientLoadPhase,
} from '~/app/@types';
import {
  PayloadAction,
  BaseNoteData,
  ConditionAssessmentPlan,
  Stats,
  IndexingData,
  BulletNoteStatusRecord,
  PreviousConditionAssessmentPlan,
  APINote,
  NotificationAttrs,
  Bullet,
  EligibleHtmlBaseNote,
  APIOrganization,
  NoteBlock,
  LoginError,
} from '~/app/@types/state';
import {
  REQUEST_NOTE_INTERVAL,
  REQUEST_NOTE_RETRIES,
  RECEIVE_REGARD_NOTE_FAILURE_STATUS,
  RECENCY_THRESHOLD_PARAM,
  CONDITIONS_PARAM,
  ENCOUNTER_PARAM,
  FORCE_FETCH_PARAM,
  REVIEW_PARAM,
  TIMESTAMP_PARAM,
  PATIENT_PARAM,
} from '~/app/constants';
import { AppDispatch, AppThunkAction } from '~/app/store';
import { RegardApiError, RegardNoteError } from '~/app/errors';
import { persistBulletNoteStatusDiffs } from '~/app/controllers/bulletNoteStatus';
import { fetchHistoricalProps } from '~/app/controllers/historicalConditionText';
import { regardNotifications } from '~/app/controllers/notifications';
import {
  meshBaseNoteWithRegardConditions,
  concatMatchedConditionAssessmentPlans,
  MeshedNoteData,
  getBlankBaseNote,
} from '~/app/controllers/regardNote';
import { getSimpleStats } from '~/app/analytics/noteMeshingStats';

import {
  generateConditionNameToKeywordRegexMap,
  generateConditionQualifiersRegexMap,
} from '~/app/controllers/noteMeshing/generateRegexMaps';
import { fetchDraft, persistMeshedNote } from '~/app/api';
import { track } from '~/app/analytics';
import * as flags from '~/app/flags';
import {
  noteBlocksToHtmlString,
  getLocalStorageItem,
  getCyobIdMapInLocalStorage,
  getPhysicalExamStorageKey,
  removeCyobIdMapInLocalStorage,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '~/app/utils';
import { getOAuthSubject } from '~/app/cookies';

import { handleErrorThunk } from './login';
import { UndoRedoItemState } from '../controllers/undoManager';
import { unspecifiedRegardConditions } from '../controllers/noteMeshing/generateKeywords/createTooltipText';

declare global {
  interface Window {
    providerTimezone: string;
    encrypted?: {
      encryptedMrn: string;
      encryptedEncStart: string;
      encryptedName: string;
      encryptedPatientId: string;
      hashedPatientId: string;
    };
  }
}

// consts /////////
const MAX_FETCH_NOTE_RETRIES = 1;

// Action Names //////////
export const RECEIVE_REGARD_NOTE_FAILURE = 'failed to receive Regard note for patient';
export const REQUEST_REGARD_NOTE = 'fetch Regard note for patient';
export const FETCH_PREV_PROPS_FOR_NEW_BASENOTE =
  'fetching historical props for newly selected base note';

// Action Types //////////
export type AddConditionAtConditionIdAction = PayloadAction<
  'add a condition at index',
  {
    index: number;
  }
>;

export type FormatNoteTitlesAction = PayloadAction<
  'format note titles',
  {
    format: 'hash' | 'number';
  }
>;

export type TypingDirection = 'forward' | 'backward';

export type ModifyNoteBlockAction = PayloadAction<
  'modify note block',
  | {
      addBulletText: string;
      conditionId: string;
      type: 'addBullet';
    }
  | {
      oneLinerText: string;
      type: 'addOneLiner';
    }
  | {
      conditionId: string;
      type: 'dismiss';
    }
  | {
      conditionId: string;
      type: 'shelve';
    }
  | {
      bulletStatus: BulletNoteStatus;
      bulletText: string;
      conditionId: string;
      type: 'hideOrMonitorBullet';
    }
  | {
      fromIndex: number;
      toIndex: number;
      top: number; // distance between condition el's top and it's container's top
      type: 'move';
    }
  | {
      conditionHtml: HtmlString;
      type: 'restore';
    }
  | {
      noteBlockCaretPos: number;
      noteBlockHtml: HtmlString;
      noteBlockId: string;
      type: 'shelfDividerTyping';
    }
  | {
      noteBlockCaretPos: number;
      conditionHtml: HtmlString;
      conditionId: string;
      type: 'typing';
    }
  | {
      type: 'merge';
      conditionId: string;
      direction: TypingDirection;
    }
>;

export type RemeshedWithNewBaseNotePayload = {
  baseNoteData: BaseNoteData;
  bulletsByModule: Record<string, Bullet[]>;
  bulletNoteStatusByBulletId: BulletNoteStatusRecord;
  indexingData: IndexingData;
  masterNoteBlocks: NoteBlock[];
  notifications: NotificationAttrs[];
  previousConditionResults: PreviousConditionAssessmentPlan[];
  stats: Stats;
};

export type RemeshedWithNewBaseNote = PayloadAction<
  "completed the process of re-meshing today's Regard props with the new basenote",
  RemeshedWithNewBaseNotePayload
>;

export type ReceiveNotePayload = {
  baseNoteData: BaseNoteData;
  baseNotes: EligibleHtmlBaseNote[];
  bulletsByModule: Record<string, Bullet[]>;
  currentConditionResults: ConditionAssessmentPlan[];
  bulletNoteStatusByBulletId: BulletNoteStatusRecord;
  encounterId: string;
  encounterStart: ISODateString | null;
  errors: { module: string }[];
  indexingData: IndexingData;
  masterNoteBlocks: NoteBlock[];
  nonHospitalProblemModules: string[];
  notifications: NotificationAttrs[];
  patientMrn: string;
  physicianId: string;
  physicalExamKey: string;
  previousConditionResults: PreviousConditionAssessmentPlan[];
  pt: string;
  stats: Stats;
  timestamp: ISODateString;
};
export type ReceiveNoteAction = PayloadAction<
  'successfully received /doc response for patient',
  ReceiveNotePayload
>;

type UndoOrRedoNoteBlocksPayload = UndoRedoItemState;
export type UndoOrRedoNoteBlocksAction = PayloadAction<
  'undo or redo note sections',
  UndoOrRedoNoteBlocksPayload
>;
export const undoOrRedoNoteBlocks: ActionCreator<UndoOrRedoNoteBlocksAction> = (payload) => ({
  payload,
  type: 'undo or redo note sections',
});

export const formatNoteTitles: ActionCreator<FormatNoteTitlesAction> = (payload) => ({
  payload,
  type: 'format note titles',
});

export type UpdatePatientLoadProgressPayload = {
  percentage: number;
  phase: PatientLoadPhase;
  estimatedPatientLoadDuration: PatientLoadDurations;
};
export type UpdatePatientLoadProgressAction = PayloadAction<
  'update patient load progress',
  UpdatePatientLoadProgressPayload
>;

export type RegardNoteAction =
  | AddConditionAtConditionIdAction
  | FormatNoteTitlesAction
  | ModifyNoteBlockAction
  | ReceiveNoteAction
  | RemeshedWithNewBaseNote
  | UndoOrRedoNoteBlocksAction
  | UpdatePatientLoadProgressAction
  | { type: typeof RECEIVE_REGARD_NOTE_FAILURE; error: LoginError }
  | { type: typeof REQUEST_REGARD_NOTE }
  | { type: typeof FETCH_PREV_PROPS_FOR_NEW_BASENOTE };

// Action Creators //////////

export const updateBulletNoteStatusFromBulletMonitorThunk =
  ({
    conditionId,
    newBulletNoteStatus,
    text,
  }: {
    conditionId: string;
    newBulletNoteStatus: BulletNoteStatus;
    text: string;
  }): AppThunkAction =>
  (dispatch): void => {
    if (conditionId) {
      dispatch({
        type: 'modify note block',
        payload:
          newBulletNoteStatus === BulletNoteStatus.Noted // add bullet
            ? {
                addBulletText: text,
                conditionId,
                type: 'addBullet',
              }
            : {
                bulletStatus: newBulletNoteStatus,
                bulletText: text,
                conditionId,
                type: 'hideOrMonitorBullet',
              },
      });
    }
  };

export const updateBulletNoteStatusFromDxDetailsThunk =
  ({
    conditionName,
    newBulletNoteStatus,
    text,
  }: {
    conditionName: string;
    newBulletNoteStatus: BulletNoteStatus;
    text: string;
  }): AppThunkAction =>
  (dispatch, getState): void => {
    const state = getState();

    const conditionId = Object.values(state.regardNote.conditionsById).find(({ modules }) =>
      modules.includes(conditionName)
    )?.id;

    if (conditionId) {
      dispatch({
        type: 'modify note block',
        payload:
          newBulletNoteStatus === BulletNoteStatus.Noted // add bullet
            ? {
                addBulletText: text,
                conditionId,
                type: 'addBullet',
              }
            : {
                bulletStatus: newBulletNoteStatus,
                bulletText: text,
                conditionId,
                type: 'hideOrMonitorBullet',
              },
      });
    }
  };

/* eslint-disable camelcase */
type FetchNoteResponse = {
  base_notes: EligibleHtmlBaseNote[];
  bullet_note_status: BulletNoteStatusRecord;
  current: {
    condition_results: APINote;
    encounter_id: string;
    encounter_start: ISODateString | null;
    errors: { module: string }[];
    mrn: string;
    timestamp: ISODateString | null;
  };
  encounter_organization: APIOrganization | null;
  encounter_type: string | null;
  failures: { resource_type: string; human_readable: string }[];
  initial_base_note_id: string | null;
  initial_base_note_draft_html: HtmlString | null;
  initial_base_note_draft_content_hash: string | null;
  keywords: Record<string, string>; // key is regard module ("anemia"), value is keyword ("blood loss")
  patient_id: string;
  previous_condition_results?: PreviousConditionAssessmentPlan[];
  non_hospital_problem_modules: string[];
  qualifiers: Record<string, Record<string, string>>; // key is regard module, value is qualifier type match ("systolic": "arterial|systolic")
  timezone: string;
};

type FetchNoteLoadingResponse = {
  status: {
    percentage: number;
    phase: PatientLoadPhase;
    estimated_patient_load_duration?: PatientLoadDurations;
  };
};
/* eslint-enable camelcase */

export const fetchPrevPropsAndRemesh =
  (selectedBaseNote: EligibleHtmlBaseNote, options: { useDraft: boolean }): AppThunkAction =>
  async (dispatch, getState): Promise<void> => {
    dispatch({ type: FETCH_PREV_PROPS_FOR_NEW_BASENOTE });
    const { useDraft } = options;
    const {
      encounterId,
      bulletNoteStatusByBulletId,
      indexingData,
      previousConditionResults: statePreviousConditionResults,
      currentConditionResults,
      physicalExamKey,
    } = getState().regardNote;

    const previous = await fetchHistoricalProps({ encounterId });

    // if previous is null that means we don't have any previous props for this timestamp
    // or the server encountered an error. Just use the prevProps already in the store if this happens
    const previousConditionResults = Array.isArray(previous)
      ? previous
      : statePreviousConditionResults;

    // we need remove all "draft" items from localStorage so system will
    // mesh with the selectedProgressNote instead of the draft stored in localStorage
    removeLocalStorageItem(physicalExamKey);

    // Mesh with the new base note text and the fetched prev props
    const reMeshedNoteData = await meshBaseNoteWithRegardConditions({
      baseNote: selectedBaseNote,
      useDraft,
      currentConditionAssessmentPlans: currentConditionResults,
      previousConditionAssessmentPlans: previousConditionResults,
      bulletNoteStatusFromAPI: bulletNoteStatusByBulletId,
      conditionNameToKeywordRegex: indexingData.conditionKeywords,
      conditionQualifiers: indexingData.conditionQualifiers,
      encounterId,
      physicalExamKey,
    });

    const notifications = regardNotifications({
      dispatch,
      draft: {
        baseNoteContentHash: reMeshedNoteData.baseNoteData.contentHash,
      },
      conditions: reMeshedNoteData.conditions,
      currentConditionResults,
      bulletNoteStatus: reMeshedNoteData.bulletNoteStatusByBulletId,
      errors: [],
      stats: reMeshedNoteData.stats,
      selectedBaseNote,
    });

    const payload: RemeshedWithNewBaseNotePayload = {
      ...reMeshedNoteData,
      bulletsByModule: _.mapValues(reMeshedNoteData.conditions, ({ bullets }) => bullets),
      notifications,
    };

    // SIDE EFFECT: Store the meshed note in the database (Unless review or sales demo mode)
    // We also store the note content like this when the user clicks "copy note."  We analyze
    // how the content changed from meshed -> copied to understand users' behavior
    if (!flags.isReview() && !flags.isSalesDemoMode) {
      const meshedNoteText = noteBlocksToHtmlString(reMeshedNoteData.masterNoteBlocks);

      persistMeshedNote(
        selectedBaseNote.resourceId,
        encounterId,
        reMeshedNoteData.baseNoteData.contentHash,
        meshedNoteText
      );
    }

    // SIDE EFFECT: Persist any bulletNoteStatus changes to DB. For example if DB has a record saying
    // bullet kidney_disease.most-recent-scr is "hidden" but we find an exact match for that bullet
    // in the baseNote, we need to persist a "noted" state for this bullet to the DB
    persistBulletNoteStatusDiffs(
      payload.bulletNoteStatusByBulletId,
      bulletNoteStatusByBulletId,
      encounterId,
      indexingData.negativeDiagnosisSet
    );

    track.stopTimeToChangeBaseNote();

    dispatch({
      type: "completed the process of re-meshing today's Regard props with the new basenote",
      payload,
    });
  };

const getParamsString = (params: Record<string, string | null | undefined>): string => {
  const urlSearchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (!_.isNil(value)) {
      urlSearchParams.set(key, value);
    }
  });
  return urlSearchParams.toString();
};

// Reloads the current base note with no draft, effectively "clearing" it.
export const fetchPrevPropsAndRemeshWithNoDraft =
  (currentBaseNote: BaseNoteData): AppThunkAction =>
  async (dispatch): Promise<void> => {
    const selectedBaseNote = {
      author: currentBaseNote.author,
      effective: currentBaseNote.effectiveTimestamp || new Date().toISOString(),
      dateSigned: currentBaseNote.dateSignedTimestamp || new Date().toISOString(),
      physicalExamText: currentBaseNote.physicalExamText,
      resourceId: currentBaseNote.resourceId,
      noteHtml: currentBaseNote.noteHtml,
      htmlVersion: currentBaseNote.htmlVersion,
      contentHash: currentBaseNote.contentHash,
      noteType: currentBaseNote.baseNoteType,
      abbrNoteType: currentBaseNote.baseNoteType,
      rawNoteType: currentBaseNote.baseNoteType,
      // `status` and `currentEncounter` are used in the select base note
      // UI and are are not necessary for remeshing.
      status: '',
      currentEncounter: true,
    };

    // Reuse the logic of selecting a basenote but do not allow a draft to be used, effectivly
    // using the current base note but "clearing" the current draft
    dispatch(fetchPrevPropsAndRemesh(selectedBaseNote, { useDraft: false }));
  };

type FetchNoteResult =
  | {
      response: Response;
      status: 0;
    }
  | {
      estimatedPatientLoadDuration: PatientLoadDurations;
      percentage: number;
      phase: PatientLoadPhase;
      sentTimestamp: DateNumberValue;
      status: 202;
    }
  | {
      json: FetchNoteResponse;
      receivedTimestamp: DateNumberValue;
      sentTimestamp: DateNumberValue;
      status: 200;
    };

const fetchNote = async ({
  originalSentTimestamp,
  url,
}: {
  originalSentTimestamp: DateNumberValue | undefined;
  url: string;
}): Promise<FetchNoteResult> => {
  const sentTimestamp = originalSentTimestamp || Date.now(); // should be placed before fetch call
  const { cyCacheKey, cyCachePatient, isDevCachePatient } = flags;

  // First, see if we should pull this patient from our cache (for cypress)
  if (cyCachePatient && cyCacheKey) {
    const cyCachedResponse = getLocalStorageItem(cyCacheKey);
    if (cyCachedResponse) {
      console.warn('loading cy cached patient');
      return {
        json: JSON.parse(cyCachedResponse),
        receivedTimestamp: Date.now(),
        sentTimestamp,
        status: 200,
      };
    }
  }

  // Then, see if we should pull this patient from our cache (for dev)
  const devCacheKey = url;
  if (isDevCachePatient) {
    const cachedResponse = getLocalStorageItem(devCacheKey);
    if (cachedResponse) {
      console.warn('loading cached patient');
      return {
        json: JSON.parse(cachedResponse),
        receivedTimestamp: Date.now(),
        sentTimestamp,
        status: 200,
      };
    }
  }

  const fetchAndHandleResponse = async (
    retriesRemaining = MAX_FETCH_NOTE_RETRIES
  ): Promise<FetchNoteResult> => {
    const response = await fetch(url, { credentials: 'include' });
    let responseBody: undefined | string;

    if (response.status === 200) {
      try {
        // Parsing the response body as JSON is done in a try-catch block because the response is
        // sometimes invalid JSON. The catch block will retry once. It's not clear why the response
        // is sometimes invalid, considering that the status code is 200.
        responseBody = await response.text();
        const json: FetchNoteResponse = JSON.parse(responseBody);

        // And cache the response for cypress
        if (cyCachePatient && cyCacheKey) {
          console.warn('caching cy patient');
          setLocalStorageItem(cyCacheKey, responseBody);
        }

        // And cache the response for dev
        if (isDevCachePatient) {
          console.warn('caching patient');
          setLocalStorageItem(devCacheKey, responseBody);
        }

        return {
          json,
          receivedTimestamp: Date.now(),
          sentTimestamp,
          status: response.status,
        };
      } catch (e) {
        const shouldRetry = retriesRemaining > 0;

        // Create Sentry issue.
        captureException(e, (scope) => {
          scope.setTransactionName(
            `/api/document returned a 200 without valid JSON, ${shouldRetry ? '' : 'not '}retrying`
          );
          // Truncate responseBody because Sentry rejects requests with either HTTP 400 Bad Request
          // or HTTP 405 Method Not Allowed if they're too large.
          scope.setExtra('response', responseBody?.substring(0, 100000));
          return scope;
        });

        if (shouldRetry) {
          return fetchAndHandleResponse(retriesRemaining - 1);
        }

        // No more retries. `status: 0` will be returned.
      }
    }

    // Totally normal; means the server needs more time to retrieve/process the note data
    if (response.status === 202) {
      const json: FetchNoteLoadingResponse = await response.json();
      return {
        sentTimestamp,
        status: response.status,
        percentage: json.status.percentage,
        phase: json.status.phase,
        estimatedPatientLoadDuration:
          json.status.estimated_patient_load_duration ?? PatientLoadDuration.Short,
      };
    }

    // There was an error! Either we got a non-200 or non-202 response code
    // or we failed to parse json in the response `retriesRemaining + 1` times.
    return {
      response,
      status: 0,
    };
  };

  return fetchAndHandleResponse();
};

const getMeshedNoteData = ({
  baseNote,
  baseNoteDraftHtml,
  baseNoteContentHash,
  currentConditionResults,
  data,
  physicalExamKey,
}: {
  baseNote: EligibleHtmlBaseNote;
  baseNoteDraftHtml: HtmlString;
  baseNoteContentHash: string | null;
  currentConditionResults: ConditionAssessmentPlan[];
  data: FetchNoteResponse;
  physicalExamKey: string;
}): Promise<MeshedNoteData> => {
  const previousConditionResults = Array.isArray(data.previous_condition_results)
    ? data.previous_condition_results
    : [];

  // Build dx-keyword mapping system
  const conditionNameToKeywordRegex = generateConditionNameToKeywordRegexMap(
    currentConditionResults,
    data.keywords
  );

  // Create SpecChecker qualifier regex lookup object
  const conditionQualifiers = generateConditionQualifiersRegexMap(data.qualifiers);

  return meshBaseNoteWithRegardConditions({
    baseNote,
    draft: { html: baseNoteDraftHtml, baseNoteContentHash },
    useDraft: true,
    currentConditionAssessmentPlans: currentConditionResults,
    previousConditionAssessmentPlans: previousConditionResults,
    bulletNoteStatusFromAPI: data.bullet_note_status,
    conditionNameToKeywordRegex,
    conditionQualifiers,
    encounterId: data.current.encounter_id,
    physicalExamKey,
  });
};

const getInitialBaseNoteAndDraft = async (fetchedNote: FetchNoteResponse) => {
  // If the user clicked refresh and now the FE is reloading, check to see if a basenote
  // mapping was set, this is the basenote we want to return the user to
  const userPreferredBaseNoteResourceIdOrNull = getCyobIdMapInLocalStorage();

  // If we did find a map, we want to clear it from localStorage so if the user refreshes
  // within the EHR, we will return them to the most recent basenote again
  if (userPreferredBaseNoteResourceIdOrNull) removeCyobIdMapInLocalStorage();

  const blankBaseNote = getBlankBaseNote();
  const userPreferredBaseNote =
    userPreferredBaseNoteResourceIdOrNull === blankBaseNote.resourceId
      ? blankBaseNote
      : _.find(
          fetchedNote.base_notes,
          (note) => note.resourceId === userPreferredBaseNoteResourceIdOrNull
        );
  const fetchedNoteInitialBaseNote =
    _.find(
      fetchedNote.base_notes,
      (note) => note.resourceId === fetchedNote.initial_base_note_id
    ) ?? blankBaseNote;
  const initialBaseNote = userPreferredBaseNote ?? fetchedNoteInitialBaseNote;

  let baseNoteDraftHtml = fetchedNote.initial_base_note_draft_html ?? ('' as HtmlString);

  if (
    userPreferredBaseNoteResourceIdOrNull &&
    initialBaseNote.resourceId === userPreferredBaseNoteResourceIdOrNull
  ) {
    // attempt to load most recent draft of this basenote
    const draftResponse = await fetchDraft(
      userPreferredBaseNoteResourceIdOrNull,
      fetchedNote.current.encounter_id
    );
    if (draftResponse.status === 'success' && draftResponse.result) {
      baseNoteDraftHtml = draftResponse.result.note_html ?? ('' as HtmlString);
    } else {
      // set draft HTML to blank so `getMeshedData` will use `initialBaseNote` instead
      baseNoteDraftHtml = '' as HtmlString;
    }
  }

  return { initialBaseNote, baseNoteDraftHtml };
};

const dispatchNote = ({
  currentConditionResults,
  data,
  dispatch,
  meshedNoteData,
  physicianId,
  pt,
  physicalExamKey,
  selectedBaseNote,
}: {
  currentConditionResults: ConditionAssessmentPlan[];
  data: FetchNoteResponse;
  dispatch: AppDispatch;
  meshedNoteData: MeshedNoteData;
  physicianId: string;
  pt: string;
  physicalExamKey: string;
  selectedBaseNote: EligibleHtmlBaseNote;
}) => {
  const ptTimestamp = data.current.timestamp;

  const failures = [];
  for (let i = 0; i < data.failures.length; i++) {
    // need naive loop to avoid snake case in type annotation in order to pass linter
    const failure = data.failures[i];
    failures.push({
      resourceType: failure.resource_type,
      humanReadable: failure.human_readable,
    });
  }

  const noteDraft = {
    baseNoteContentHash: data.initial_base_note_draft_content_hash,
  };

  const notifications = regardNotifications({
    dispatch,
    draft: noteDraft,
    conditions: meshedNoteData.conditions,
    currentConditionResults,
    bulletNoteStatus: meshedNoteData.bulletNoteStatusByBulletId,
    errors: [],
    stats: meshedNoteData.stats,
    selectedBaseNote,
  });

  const payload: ReceiveNotePayload = {
    bulletsByModule: _.mapValues(meshedNoteData.conditions, ({ bullets }) => bullets),
    currentConditionResults,
    encounterId: data.current.encounter_id,
    encounterStart: data.current.encounter_start as ISODateString,
    errors: data.current.errors,
    patientMrn: data.current.mrn,
    physicianId,
    nonHospitalProblemModules: data.non_hospital_problem_modules,
    baseNotes: data.base_notes,
    pt,
    timestamp: ptTimestamp as ISODateString,
    ...meshedNoteData,
    physicalExamKey,
    notifications,
  };

  dispatch({
    type: 'successfully received /doc response for patient',
    payload,
  });
};

// This API call is the keystone of our app. When the user opens Regard, this is the main API
// fetch to get the base notes, run of all condition modules and hisotircal props to mesh
// for this patient/encounter
export const fetchNoteThunk =
  (
    // eslint-disable-next-line default-param-last
    retries = REQUEST_NOTE_RETRIES,
    originalSentTimestamp?: DateNumberValue // should only be set on recursive call
  ): AppThunkAction =>
  async (dispatch) => {
    // Handle exceeding the maximum number of retries
    if (retries <= 0) {
      dispatch({
        type: RECEIVE_REGARD_NOTE_FAILURE,
        error: { status: RECEIVE_REGARD_NOTE_FAILURE_STATUS },
      });
      return;
    }

    // Only fire redux and tracking events the first time this function is run
    if (!originalSentTimestamp) {
      dispatch({ type: REQUEST_REGARD_NOTE });
      track.requestingHTDXsAndPatientNotesfromAPI();
    }

    const params = new URLSearchParams(window.location.search);
    const patientParam = params.get(PATIENT_PARAM);
    const physicianId = getOAuthSubject();
    const urlPath = '/api/document'; // For testing: '/api/test/document'
    const urlParams = getParamsString({
      dr_group: 'hospitalists',
      html_version: `${flags.getCurrentHtmlVersion()}`,
      [CONDITIONS_PARAM]: params.get(CONDITIONS_PARAM),
      [ENCOUNTER_PARAM]: params.get(ENCOUNTER_PARAM),
      [FORCE_FETCH_PARAM]: params.get(FORCE_FETCH_PARAM),
      [PATIENT_PARAM]: patientParam,
      [RECENCY_THRESHOLD_PARAM]: params.get(RECENCY_THRESHOLD_PARAM),
      [REVIEW_PARAM]: params.get(REVIEW_PARAM),
      [TIMESTAMP_PARAM]: params.get(TIMESTAMP_PARAM),
    });
    const url = `${urlPath}?${urlParams}`;
    let result: Awaited<ReturnType<typeof fetchNote>> | undefined;

    try {
      result = await fetchNote({
        originalSentTimestamp,
        url,
      });

      if (result.status === 200) {
        const { json, receivedTimestamp, sentTimestamp } = result;

        // If the server returned a 200 with a JSON response, clean and dispatch
        const data = json;

        const currentConditionResults = concatMatchedConditionAssessmentPlans(
          data.current.condition_results
        );

        const { initialBaseNote, baseNoteDraftHtml } = await getInitialBaseNoteAndDraft(data);

        const physicalExamKey = getPhysicalExamStorageKey(
          data.current.mrn,
          physicianId,
          initialBaseNote?.effective // we want to use the same key for blank base notes
        );

        // SIDE EFFECT 1: Store timezone of the note on the window object.
        // This is used for all date formatting into user-readable strings, and
        // needs to be set before any note parsing/meshing
        window.providerTimezone = '';
        if (data.timezone && data.timezone.startsWith('America/')) {
          window.providerTimezone = data.timezone;
        } else {
          withScope((scope) => {
            scope.setExtra('data.timezone', data.timezone);
            captureException(new RegardNoteError('No Valid Timezone Provided By Server Response'));
          });
        }

        const meshedNoteData = await getMeshedNoteData({
          baseNote: initialBaseNote,
          baseNoteContentHash: data.initial_base_note_draft_content_hash ?? null,
          baseNoteDraftHtml,
          currentConditionResults,
          data,
          physicalExamKey,
        });

        /// ///////////
        // Analytics //
        /// ///////////
        window.regardEncounterId = data.current.encounter_id;
        window.regardEncounterOrganization = data.encounter_organization
          ? _.pick(data.encounter_organization, ['resource_id', 'name'])
          : null;
        window.regardEncounterType = data.encounter_type;
        window.regardPatientId = data.patient_id;

        // send timing stats to Amplitude and influx
        const millisecondsTaken = receivedTimestamp - sentTimestamp;

        track.totalLoadTime({ milliseconds: millisecondsTaken });

        track.loadedHTApplication({
          baseNoteType: meshedNoteData.baseNoteData.baseNoteType,
          milliseconds: millisecondsTaken,
          numberOfAvailableBaseNotes: data.base_notes.length,
          stats: getSimpleStats(
            meshedNoteData.stats,
            meshedNoteData.indexingData.conditionNameToICDCodesMap
          ),
          timestamp: data.current.timestamp ?? undefined,
          unspecifiedDXes: Object.keys(unspecifiedRegardConditions),
        });

        // SIDE EFFECT 2: Store the meshed note in the database (Unless review or sales demo mode)
        // We also store the note content like this when the user clicks "copy note."  We analyze
        // how the content changed from meshed -> copied to understand users' behavior
        if (!flags.isReview() && !flags.isSalesDemoMode) {
          const meshedNoteText = noteBlocksToHtmlString(meshedNoteData.masterNoteBlocks);

          persistMeshedNote(
            initialBaseNote.resourceId,
            data.current.encounter_id,
            meshedNoteData.baseNoteData.contentHash,
            meshedNoteText
          );
        }

        // SIDE EFFECT 3: Persist any bulletNoteStatus changes to DB. For example if DB has a record saying
        // bullet kidney_disease.most-recent-scr is "hidden" but we find an exact match for that bullet
        // in the baseNote, we need to persist a "noted" state for this bullet to the DB
        persistBulletNoteStatusDiffs(
          meshedNoteData.bulletNoteStatusByBulletId,
          data.bullet_note_status,
          data.current.encounter_id,
          meshedNoteData.indexingData.negativeDiagnosisSet
        );

        dispatchNote({
          currentConditionResults,
          data,
          dispatch,
          meshedNoteData,
          physicianId,
          pt: patientParam ?? '',
          physicalExamKey,
          selectedBaseNote: initialBaseNote,
        });
      } else if (result.status === 202) {
        const { sentTimestamp, percentage, phase, estimatedPatientLoadDuration } = result;

        dispatch({
          type: 'update patient load progress',
          payload: { percentage, phase, estimatedPatientLoadDuration },
        });
        setTimeout(
          () =>
            dispatch(
              // use original value of recencyThreshold to terminate 202 loop after data updated
              fetchNoteThunk(retries - 1, sentTimestamp)
            ),
          REQUEST_NOTE_INTERVAL
        );
      } else {
        dispatch(handleErrorThunk(RECEIVE_REGARD_NOTE_FAILURE, result.response));

        throw new Error();
      }
    } catch (e) {
      track.failedToReceiveHTDXsAndPatientNotes();
      withScope((scope) => {
        if (result?.status === 0) {
          scope.setExtra('response', result.response);
        }
        scope.setExtra('retries', retries);
        scope.setExtra('url', url);
        captureException(new RegardApiError('/api/document'));
      });
    }
  };
