import { SummaryUl, SummaryOl } from './common';

export const pneumoniaSummary = (
  <SummaryOl>
    <li>
      Pneumonia diagnostic criteria
      <br />
      Imaging criteria:
      <SummaryUl innerList>
        <li>
          Positive indications of pneumonia from chest imaging report impressions/findings
          (infiltrate, opacity, consolidation, density).
        </li>
      </SummaryUl>
      PLUS 2 or more of the following:
      <SummaryUl innerList>
        <li>Elevated temperature.</li>
        <li>Elevated WBC count.</li>
        <li>Elevated procalcitonin.</li>
        <li>
          Microbiology: positive respiratory (sputum, BAL) culture OR Streptococcus pneumoniae
          antigen OR Legionella urine antigen.
        </li>
        <li>Hypoxemic respiratory failure.</li>
        <li>
          Symptoms: “cough”, “shortness of breath”, “dyspnea”, “pleuritic chest pain”, “pleuritic
          pain” noted in notes from the current encounter.
        </li>
      </SummaryUl>
    </li>
    <li>
      Determine the type of pneumonia the patient has:
      <SummaryUl innerList>
        <li>“Community-acquired” versus “Hospital-acquired” versus “Ventilator-acquired”</li>
        <li>
          Bacterial versus viral versus fungal:
          <SummaryUl innerList>
            <li>
              Search for respiratory cultures for pathogens (expectorated sputum, induced sputum,
              tracheal aspirate, BAL, etc.)
            </li>
            <li> If respiratory culture result has organism, report organism</li>
          </SummaryUl>
        </li>
        <li>
          Aspiration pneumonia or aspiration pneumonitis:
          <SummaryUl innerList>
            <li>
              Will trigger if patient’s notes contain “aspiration pneumonia” or “aspiration
              pneumonitis”
            </li>
          </SummaryUl>
        </li>
      </SummaryUl>
    </li>
    <li>
      Determine severity of PNA by gathering supporting evidence
      <SummaryUl innerList>
        <li>Check if the patient is febrile (temp &gt; 100.4F)</li>
        <li>WBC &gt; 11[000] within 24 hours of positive chest report</li>
        <li>SpO2 &lt; 88% within 24 hours of positive chest report</li>
        <li>Respiratory rate &gt; 20 breaths/min within 24 hours of positive chest report</li>
      </SummaryUl>
    </li>
  </SummaryOl>
);
